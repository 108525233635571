<template>
  <v-dialog v-model="dialog" max-width="400">
    <template v-slot:activator="{ on: onDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-icon class="red--text" v-on="{ ...onTooltip, ...onDialog }">delete</v-icon>
        </template>
        <span>Delete</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title class="headline">Confirm - Delete Investment?</v-card-title>
      <v-card-text v-if="isLoading" class="text-center">
        <v-progress-circular indeterminate class="primary--text" :width="7" :size="90" />
      </v-card-text>
      <div v-else>
        <v-card-text>Are you sure you want to delete this investment and all associated data?</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1" />

          <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>

          <v-btn color="primary" text @click="deleteInvestment">Yes, delete</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters(["isLoading", "error"])
  },
  methods: {
    deleteInvestment() {
      this.$emit("confirm-delete");
    }
  }
};
</script>
