export const formatMixin = {
    data: () => ({
        chartBackgroundColors: [
            "#ffb980",
            "#2ec7c9",
            "#5ab1ef",
            "#b6a2de",
            "#d87a80",
            "#8d98b3",
            "#e5cf0d",
            "#97b552",
            "#95706d",
            "#dc69aa",
            "#07a2a4",
            "#9a7fd1",
            "#588dd5",
            "#f5994e",
            "#c05050",
            "#59678c",
            "#c9ab00",
            "#7eb00a",
            "#6f5553",
            "#c14089"
        ]
    }),
    methods: {
        numberWithCommas(x) {
            if (x > 0) {
                let formattedNumber = 0

                if (parseFloat(x) % 1 != 0) { formattedNumber = parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }
                else { formattedNumber = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }

                return formattedNumber
            } else if (x == 0) {
                return 0;
            } else {
                return null;
            }
        }
    }
}