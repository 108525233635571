var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"investment-list"},[_c('v-snackbar',{attrs:{"timeout":3000,"top":"","color":"success"},model:{value:(_vm.snackbar_delete),callback:function ($$v) {_vm.snackbar_delete=$$v},expression:"snackbar_delete"}},[_c('span',[_vm._v("Investment Deleted.")]),_c('v-btn',{attrs:{"text":"","dark":""},on:{"click":function($event){_vm.snackbar_delete = false}}},[_vm._v("Close")])],1),_c('v-container',{staticStyle:{"max-width":"1200px"}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.investments,"search":_vm.search,"sort-by":"date","loading":_vm.isLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"hidden-xs-and-down"},[(_vm.filteredView === 'active')?_c('v-toolbar-title',[_vm._v("Active Investments")]):(_vm.filteredView === 'exits')?_c('v-toolbar-title',[_vm._v("Exits")]):(_vm.filteredView === 'failed')?_c('v-toolbar-title',[_vm._v("Failed")]):_c('v-toolbar-title',[_vm._v("My Investments")])],1),_c('v-divider',{staticClass:"mx-4 hidden-xs-and-down",attrs:{"inset":"","vertical":""}}),_c('v-spacer',{staticClass:"hidden-xs-and-down"}),_c('v-select',{staticClass:"ma-0",staticStyle:{"max-width":"180px"},attrs:{"items":_vm.filterOptions,"label":"Filter by status","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filteredView),callback:function ($$v) {_vm.filteredView=$$v},expression:"filteredView"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.estValue",fn:function(ref){
var item = ref.item;
return [_vm._v("$ "+_vm._s(_vm.numberWithCommas(item.estValue)))]}},{key:"item.estROI",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(_vm.estROI(item.estValue, item.amount)),"dark":""}},[_vm._v(_vm._s(_vm.estROI(item.estValue, item.amount).toFixed(1))+" x")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v("$ "+_vm._s(_vm.numberWithCommas(item.amount)))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2 green--text",on:{"click":function($event){return _vm.editItem(item.id)}}},on),[_vm._v("edit")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('ConfirmDelete',{on:{"confirm-delete":function($event){return _vm.deleteItem(item.id, item.compID)}}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }