<template>
  <div class="investment-list">
    <v-snackbar v-model="snackbar_delete" :timeout="3000" top color="success">
      <span>Investment Deleted.</span>
      <v-btn @click="snackbar_delete = false" text dark>Close</v-btn>
    </v-snackbar>
    <v-container style="max-width:1200px">
      <v-card>
        <v-data-table
          :headers="headers"
          :items="investments"
          :search="search"
          sort-by="date"
          :loading="isLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div class="hidden-xs-and-down">
                <v-toolbar-title v-if="filteredView === 'active'">Active Investments</v-toolbar-title>
                <v-toolbar-title v-else-if="filteredView === 'exits'">Exits</v-toolbar-title>
                <v-toolbar-title v-else-if="filteredView === 'failed'">Failed</v-toolbar-title>
                <v-toolbar-title v-else>My Investments</v-toolbar-title>
              </div>
              <v-divider class="mx-4 hidden-xs-and-down" inset vertical></v-divider>
              <v-spacer class="hidden-xs-and-down" />
              <v-select
                class="ma-0"
                v-model="filteredView"
                :items="filterOptions"
                label="Filter by status"
                outlined
                dense
                hide-details
                style="max-width:180px"
              />
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              />
            </v-toolbar>
          </template>
          <template v-slot:item.estValue="{ item }">$ {{ numberWithCommas(item.estValue) }}</template>
          <template v-slot:item.estROI="{ item }">
            <v-chip
              :color="getColor(estROI(item.estValue, item.amount))"
              dark
            >{{ estROI(item.estValue, item.amount).toFixed(1) }} x</v-chip>
          </template>
          <template v-slot:item.amount="{ item }">$ {{ numberWithCommas(item.amount) }}</template>

          <template v-slot:item.action="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon class="mr-2 green--text" @click="editItem(item.id)" v-on="on">edit</v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <ConfirmDelete @confirm-delete="deleteItem(item.id, item.compID)" />
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// import db from "@/fb";
import ConfirmDelete from "../components/ConfirmDelete.vue";
import { formatMixin } from "../components/mixins/formatMixin.js";
import { mapGetters } from "vuex";

export default {
  name: "InvestmentList",
  components: { ConfirmDelete },
  mixins: [formatMixin],
  props: {
    companyName: {
      default: null,
      type: String
    }
  },
  data: () => ({
    snackbar_delete: false,
    //All below for v-data-table
    search: "",
    headers: [
      {
        text: "Company",
        align: "left",
        value: "company_name"
      },
      { text: "Cost Basis", value: "amount", align: "center" },
      { text: "Est Value", value: "estValue", align: "center" },
      { text: "ROI", value: "estROI", align: "center" },
      { text: "Funding Portal", value: "portal" },
      { text: "Security", value: "security" },
      { text: "Date", value: "date" },
      { text: "Actions", value: "action", sortable: false }
    ],
    filterOptions: [
      { text: "All", value: "all" },
      { text: "Active", value: "active" },
      { text: "Exits", value: "exits" },
      { text: "Failed", value: "failed" }
    ],
    filteredView: null
  }),
  computed: {
    investments() {
      let filterCompany = false;
      if (this.companyName) {
        filterCompany = this.companyName;
      } else filterCompany = false;

      if (this.filteredView === "exits") {
        return this.$store.getters.loadedInvestments.filter(
          investment =>
            investment.isExited &&
            (investment.company_name == filterCompany || !filterCompany)
        );
      } else if (this.filteredView === "active") {
        return this.$store.getters.loadedInvestments.filter(
          investment =>
            !investment.isExited &&
            !investment.isFailed &&
            (investment.company_name == filterCompany || !filterCompany)
        );
      } else if (this.filteredView === "failed") {
        return this.$store.getters.loadedInvestments.filter(
          investment =>
            investment.isFailed &&
            (investment.company_name == filterCompany || !filterCompany)
        );
      } else if (this.filteredView === "converted") {
        return this.$store.getters.loadedInvestments.filter(
          investment =>
            investment.converted.isConverted &&
            (investment.company_name == filterCompany || !filterCompany)
        );
      } else {
        return this.$store.getters.loadedInvestments.filter(
          investment =>
            investment.company_name == filterCompany || !filterCompany
        );
      }
    },
    ...mapGetters(["isLoading", "error"])
  },
  methods: {
    estROI(estValue, amount) {
      if (amount > 0 && estValue) {
        return estValue / amount;
      } else if (estValue == 0) {
        return 0;
      } else {
        return -1;
      }
    },
    getColor(estROI) {
      if (estROI == 0) return "red";
      else if (estROI < 1.0) return "orange";
      else if (estROI == 1.0) return "gray";
      else if (estROI < 10.0) return "green";
      else return "blue";
    },
    editItem(id) {
      this.$router.push("/investments/" + id);
    },
    // TODO - async wait (then) and error detection if it fails to delete
    //TODO: Match this invID with companyID - store companyID in each inv object?
    deleteItem(invID, compID) {
      this.$store.dispatch("deleteInvestment", { invID, compID }).then(() => {
        this.dialog = false;
        this.snackbar_delete = true;
      });
    }
  }
};
</script>
